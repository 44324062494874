import React from 'react';
import Container from '@mui/material/Container';

const DataDeletionInstruction = () => {
  return (
    <Container>
      <h1>Data Deletion Instructions</h1>
      <span>
        If you would like to delete your activities for the BangkokCC app, you
        can follow these steps.
      </span>
      <ol>
        <li>Go to contact page</li>
        <li>Choose "Ask for data deletion"</li>
        <li>Put your reasons</li>
        <li>Submit the form</li>
      </ol>
    </Container>
  );
};

export default DataDeletionInstruction;
